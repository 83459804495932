// make a reducer fro the platform time filter component
import * as actionTypes from './actions';
import dayjs from 'dayjs';


const initialState = {
  selectedPlatforms: [],
  selectedTimeRange: {
    startDate: dayjs().subtract(7, 'day').startOf('day').toISOString().split('Z')[0],
    endDate: dayjs().endOf('day').toISOString().split('Z')[0]
  }

};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SET_PLATFORM_FILTER:
        return {
          ...state,
          selectedPlatforms: [...action.payload]
        };
      case actionTypes.SET_TIME_RANGE_FILTER:
        return {
          ...state,
          selectedTimeRange: {
            startDate: action.payload.startDate, // Ensure new dayjs instances
            endDate: action.payload.endDate,
          }
        };
      default:
        return state;
    }
  };
  

export default filterReducer;
