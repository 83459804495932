import React, { useState } from 'react';

import { store } from '../../store/index'
import * as actionTypes from '../../store/actions';

import { 
  Box, 
  Button, 
  IconButton, 
  Typography,
  useTheme
} from '@mui/material';

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import DiscordLogo from '../../assets/images/platform-logo/discord_logo.png';
import InstagramLogo from '../../assets/images/platform-logo/insta_logo.png';
import YouTubeLogo from '../../assets/images/platform-logo/youtube_logo.png';
import TikTokLogo from '../../assets/images/platform-logo/tiktok_logo_circular.webp';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.success.dark,
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));


const PlatformTimeFilter = () => {
  const theme = useTheme();


  const platformFilters = [
    { name: 'Discord', logo: DiscordLogo },
    { name: 'Instagram', logo: InstagramLogo },
    { name: 'YouTube', logo: YouTubeLogo },
    { name: 'TikTok', logo: TikTokLogo }
  ];

  const timeFilters = [
    {
      name: "Today",
      getDates: () => {
        const today = dayjs();
        const yesterday = today.subtract(1, 'day');
        return [yesterday, today];;
      }
    },
    {
      name: "Last Week",
      getDates: () => {
        const today = dayjs();
        const lastWeek = today.subtract(7, 'day');
        return [lastWeek, today];
      }
    },
    {
      name: "Last Month",
      getDates: () => {
        const today = dayjs();
        const lastMonth = today.subtract(1, 'month');
        return [lastMonth, today];
      }
    },
    {
      name: "Q1",
      getDates: () => {
        const year = dayjs().year();
        return [dayjs(`${year}-01-01`), dayjs(`${year}-03-31`)];
      }
    },
    {
      name: "Q2",
      getDates: () => {
        const year = dayjs().year();
        return [dayjs(`${year}-04-01`), dayjs(`${year}-06-30`)];
      }
    },
    {
      name: "Q3",
      getDates: () => {
        const year = dayjs().year();
        return [dayjs(`${year}-07-01`), dayjs(`${year}-09-30`)];
      }
    },
    {
      name: "Q4",
      getDates: () => {
        const year = dayjs().year();
        return [dayjs(`${year}-10-01`), dayjs(`${year}-12-31`)];
      }
    }
  ];

  const [selectedPlatforms, setSelectedPlatform] = useState([])
  const [dateRange, setDateRange] = useState(timeFilters[1].getDates());
  const [lastSelectedTime, setLastSelectedTime] = useState(timeFilters[1])

  const handleTimeFilterChange = (filter) => {
    setLastSelectedTime(filter.name)
    const newDates = filter.getDates();
    setDateRange(newDates);
    store.dispatch({
      type: actionTypes.SET_TIME_RANGE_FILTER,
      payload: { startDate: newDates[0].toISOString().split('Z')[0], endDate: newDates[1].toISOString().split('Z')[0] }
    });
  };


  const handlePlatformChange = (platform) => {
    let newSelectedPlatforms;

    if (selectedPlatforms.some(p => p === platform)) { // Use some to check for object equality based on the name
      newSelectedPlatforms = selectedPlatforms.filter((p) => p !== platform); // Filter it out if we find it
    } else {
      newSelectedPlatforms = [...selectedPlatforms, platform]; // Add it to the list in an immutable way
    }

    setSelectedPlatform(newSelectedPlatforms);
    store.dispatch({ 
      type: actionTypes.SET_PLATFORM_FILTER, 
      payload: newSelectedPlatforms.map(p => p)
    });

  };

  const handleSingleDateChange = (newValue, index) => {
    const newDateRange = [...dateRange];
    newDateRange[index] = newValue;
    setLastSelectedTime('')
    setDateRange(newDateRange);
    store.dispatch({ 
      type: actionTypes.SET_TIME_RANGE_FILTER, 
      payload: { startDate: newDateRange[0].toISOString().split('Z')[0], endDate: newDateRange[1].toISOString().split('Z')[0] }
    });
  };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        justifyContent={{ xs: 'space-between', md: 'flex-end' }}
        width="100%"
      >
        <Box 
          display="flex" 
          flexDirection={{ xs: 'column', sm: 'row' }} 
          mr={{ xs: 0, md: 2 }} 
          mb={{ xs: 2, md: 0 }} 
          flexWrap="wrap"
          gap={{ xs: 2, sm: 1 }} // Added gap for vertical spacing
        >
          {/* Time Filters */}
          <Box
            bgcolor="rgba(47, 49, 42, 0.9)"
            borderRadius={2}
            py={0.5}
            px={1}
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
          >
            {timeFilters.map((filter) => (
              <Button
                key={filter.name}
                onClick={() => handleTimeFilterChange(filter)}
                sx={{
                  color: 'white',
                  fontSize: '0.8rem',
                  padding: '2px 8px',
                  minWidth: 'auto',
                  margin: '2px',
                  bgcolor: filter.name === lastSelectedTime
                    ? theme.palette.success.dark 
                    : 'transparent',
                  '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                }}
              >
                {filter.name}
              </Button>
            ))}
          </Box>

          {/* Date Range Picker */}
          <Box
            bgcolor="rgba(47, 49, 42, 0.9)"
            borderRadius={2}
            py={0.5}
            px={1}
          >
            <DateTimePicker
              value={dateRange[0]}
              onChange={(newValue) => handleSingleDateChange(newValue, 0)}
              renderInput={(params) => (
                <Typography
                  {...params}
                  variant="standard"
                  sx={{
                    '& .MuiInputBase-root': {
                      border: 'none',
                      borderRadius: 2,
                    },
                    '& .MuiInputBase-underline:before': {
                      borderBottom: 'none',
                    },
                    '& .MuiInputBase-underline:after': {
                      borderBottom: 'none',
                    },
                  }}
                />
              )}
            />
            <DateTimePicker
              value={dateRange[1]}
              onChange={(newValue) => handleSingleDateChange(newValue, 1)}
              renderInput={(params) => (
                <Typography
                  {...params}
                  variant="standard"
                  sx={{
                    '& .MuiInputBase-root': {
                      border: 'none',
                      borderRadius: 2,
                    },
                    '& .MuiInputBase-underline:before': {
                      borderBottom: 'none',
                    },
                    '& .MuiInputBase-underline:after': {
                      borderBottom: 'none',
                    },
                    background: 'transparent',
                  }}
                />
              )}
            />
          </Box>
              
          {/* Platform Filters */}
          <Box
            bgcolor="rgba(47, 49, 42, 0.9)"
            borderRadius={2}
            py={0.5}
            px={1}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            justifyContent="center"
          >
            {platformFilters.map((platform) => (
              <LightTooltip
                key={platform.name}
                title={platform.name}
              >
                <IconButton
                  onClick={() => handlePlatformChange(platform.name)}
                  sx={{
                    padding: '2px',
                    margin: '2px',
                    opacity: selectedPlatforms.length == 0 || selectedPlatforms.includes(platform.name) 
                      ? 1 
                      : 0.5,
                    '&:hover': { opacity: 1 },
                    borderRadius: '50%',
                    width: '24px',
                    height: '24px'
                  }}
                >
                  <img 
                    src={platform.logo} 
                    alt={platform.name} 
                    style={{ width: '20px', height: '20x' }} 
                  />
                </IconButton>
              </LightTooltip>
            ))}
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default PlatformTimeFilter;