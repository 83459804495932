// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';


// action - filter reducer
export const SET_PLATFORM_FILTER = '@filter/SET_PLATFORM_FILTER';
export const SET_TIME_RANGE_FILTER = '@filter/SET_TIME_RANGE_FILTER';
