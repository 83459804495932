import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import React from 'react'; 
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

import ErrorBoundary from 'views/error/ErrorBoundary'

import { useState } from 'react';

// ==============================|| APP ||============================== //

const App = () => {
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const [loading, setLoading] = useState(true)

  
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user')) 
    const currentCommunity = JSON.parse(localStorage.getItem('currentCommunity'))
    const communities = JSON.parse(localStorage.getItem('communities'))

    if (user == null || user == undefined || currentCommunity == null || communities == null) {
      if (window.location.pathname !== '/login' && window.location.pathname !== '/register') {
        navigate('/login'); 
      }
    }
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        localStorage.setItem(
          'user',
          JSON.stringify({
            accessToken: user.accessToken,
            uid: user.uid,
            email: user.email,
            name: user.displayName,
          })
        );
      } else {
        if (window.location.pathname !== '/login' && window.location.pathname !== '/register') {
          navigate('/login'); 
        }
      }
    });
    setLoading(false);

    // Clean up the listener when the component unmounts
  }, []);
  if (!loading) 
    return (
      <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
            </NavigationScroll>
          </ThemeProvider>
      </StyledEngineProvider>
    );
}

export default App;

