import dashboard from './dashboard';
import communitypages from './communitypages';
// import pages from './pages';
import setup from './setup'
//import utilities from './utilities';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, communitypages, setup]
  // items: [dashboard, communitypages]
};

export default menuItems;

