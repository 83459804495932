import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import filterReducer from './filterReducer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  filter: filterReducer
});

export default reducer;
